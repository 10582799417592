import React from 'react'
import AccordionContentLayout from '../Layouts/AccordionContentLayout'

const ManageUsers = () => {
    return (
        <AccordionContentLayout complete titulo="Administrar Usuarios">
        </AccordionContentLayout>
    )
}

export default ManageUsers