import React from 'react'
import AccordionContentLayout from '../Layouts/AccordionContentLayout'

const ManageReports = () => {
  return (
    <AccordionContentLayout complete titulo="Administrar Reportes">
    </AccordionContentLayout>
  )
}

export default ManageReports